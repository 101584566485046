:root {
  --primary: #104470;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  /*-webkit-touch-callout: none !important;*/
  /*-webkit-touch-callout: none;*/
  /*-webkit-user-select: none;*/
  /*-khtml-user-select: none;*/
  /*-moz-user-select: none;*/
  /*-ms-user-select: none;*/
  /*user-select: none;*/
}

a {
  color: inherit;
  text-decoration: none;
  -webkit-user-select: none !important;
}

* {
  box-sizing: border-box;
}

img {
  -webkit-user-select: none !important;
}

amplify-s3-image {
  --width: 100%;
  --height: 100%;
}

@media (prefers-color-scheme: light) {
  html {
    color-scheme: light;
  }
  body {
    color: black;
    background: white;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.MuiDataGrid-iconSeparator {
  display: none !important;
}

.MuiDataGrid-columnHeader,
.MuiDataGrid-cell {
  border-right: 1px solid #d9d9d9;
}
.MuiDataGrid-columnHeader {
  border-bottom: 2px solid black;
}
